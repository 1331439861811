import React from "react";
import { Grid, Typography, Box, IconButton, Link } from "@mui/material";

import { ReactComponent as TwitterIcon } from "../assets/svg/twitter-brands.svg";
import { ReactComponent as FacebookIcon } from "../assets/svg/facebook-f-brands.svg";
import { ReactComponent as LinkedInIcon } from "../assets/svg/linkedin-in-brands.svg";
import { ReactComponent as EmailIcon } from "../assets/svg/envelope-regular.svg";
import { ReactComponent as LogoText } from "../assets/svg/logo-text.svg";

const style = {
  footer: {
    backgroundColor: (theme) => theme.palette.primary.main,
    padding: "80px 5px",
  },
  socialIcons: {
    color: (theme) => theme.palette.common.white,
    border: 2,
    borderColor: (theme) => theme.palette.primary.light,
    "&:hover": {
      border: 2,
      backgroundColor: (theme) => theme.palette.common.white,
      color: (theme) => theme.palette.primary.main,
    },
  },
};

export default function Footer() {
  return (
    <Box component="footer" sx={style.footer}>
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ paddingTop: 10 }}
      >
        <Grid item>
          <Box
            sx={{ height: 40, color: "#000" }}
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <LogoText style={{ height: "inherit", color: "inherit" }} />
          </Box>
        </Grid>
        <Grid item sx={{ paddingTop: 1 }}>
          <Typography
            sx={{
              color: (theme) => theme.palette.common.white,
              fontSize: "1.2rem",
              textAlign: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="800"
          >
            First, solve the problem. Then, write the code
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          sx={{ paddingTop: 5 }}
        >
          <Grid item>
            <IconButton
              color="secondary"
              sx={style.socialIcons}
              size="large"
              component={Link}
              href="https://twitter.com/itsjnathan"
              target="_blank"
              rel="noopener"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              <TwitterIcon
                style={{ width: 20, height: 20, color: "inherit" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="secondary"
              sx={style.socialIcons}
              size="large"
              component={Link}
              href="https://facebook.com/itsjnathan"
              target="_blank"
              rel="noopener"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="700"
            >
              <FacebookIcon
                style={{ width: 20, height: 20, color: "inherit" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="secondary"
              sx={style.socialIcons}
              size="large"
              component={Link}
              href="https://www.linkedin.com/in/itsjnathan"
              target="_blank"
              rel="noopener"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="800"
            >
              <LinkedInIcon
                style={{ width: 20, height: 20, color: "inherit" }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="secondary"
              sx={style.socialIcons}
              size="large"
              component={Link}
              href="mailto:itsjnathan@gmail.com"
              target="_blank"
              rel="noopener"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="900"
            >
              <EmailIcon style={{ width: 20, height: 20, color: "inherit" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item sx={{ paddingTop: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.common.white }}
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="1000"
            data-aos-offset="60"
          >
            Handcrafted by me © itsjnathan
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
